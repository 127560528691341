import { Stack } from "@chakra-ui/layout";
import { Section } from "../../layout/Section";
import React from "react";
import ItSolutions from "./ItSolutions";
import Guide from "./Guide";
import SystemCommunication from "./Communication";
import Category from "./Category";
import VisualLabLink from "./VisualLabLink";

interface SystemSectionProps {}

const SystemSection: React.FC<SystemSectionProps> = () => {
  return (
    <Section>
      <Stack spacing={20}>
        <Category />
        <ItSolutions />
        <Guide />
        <SystemCommunication />
        <VisualLabLink />
      </Stack>
    </Section>
  );
};

export default SystemSection;
