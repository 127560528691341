import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { Subtitle } from "../../texts/Subtitle";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../../texts/Hero";

interface ItSolutionsProps { }

const ItSolutions: React.FC<ItSolutionsProps> = () => {
  return (
    <TextAndVisual
      isVisualLeft
      isVisualMedium
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero>We create well-oiled IT solutions</Hero>
          <BodyText textAlign={"left"}>
            Our background lies in digitalising industrial and logistical
            processes. We are experts at building and connecting the automation
            that takes place in production, maintenance, warehouses and
            transportation to sales, billing and business analysis – allowing
            data to flow internally and giving you a solid base for decisions
            and efficient work.
          </BodyText>
          <BodyText textAlign={"left"}>
            To create well-oiled IT solutions, we work in
            close cooperation with our customers. When turning to us, it is
            enough to have an idea of the challenge your company is facing. We
            define a cost-effective solution by analysing and mapping your
            internal processes. Our code is tailor-made and programmed to evolve
            with your operations as you grow and your needs change over time.
          </BodyText>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end">
          <StaticImage
            alt="We create well-oiled IT solutions"
            src="../../../images/indevit-04.jpg"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default ItSolutions;
