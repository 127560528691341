import React from "react";
import { Layout } from "../../components/layout/Layout";
import SystemSection from "../../components/sections/system/SystemSection";
import { SystemHero } from "../../components/sections/system/SystemHero";
import { MetaTags } from "../../components/MetaTags";

interface SystemProps {}

const System: React.FC<SystemProps> = () => {
  return (
    <Layout colorScheme="primaryFullBackground" heroSection={<SystemHero />} isNextSectionInitiallyVisible>
      <SystemSection />
    </Layout>
  );
};

export default System;

export const Head = () => (
  <MetaTags
    title="Business Systems"
    description="Smart, automated processes save companies resources and money on a
      daily basis. At Indevit, we design and optimise IT systems that create
      growth and competitive advantages, so that you and your team can focus
      on your core."
    image="systems.jpg"
  />
)