import { Flex, Grid } from "@chakra-ui/react";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import VerticalTextAndVisual from "../../layout/VerticalTextAndVisual";

interface CategoryProps { }

const Category: React.FC<CategoryProps> = () => {
  return (
    <Grid
      gap={8}
      gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr 1fr" }}
    >
      <VerticalTextAndVisual
        subtitle="Software development"
        body="Functional and reliable digital solutions that empower businesses and transform ideas into reality."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="Software development"
            src="../../../images/service-icons-01.png"
            placeholder="none"
          />
        }
      />
      <VerticalTextAndVisual
        subtitle="Business intelligence"
        body="Harnessing data-driven insights to empower informed decisions and enable strategic growth for organizations."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="Business intelligence"
            src="../../../images/service-icons-02.png"
            placeholder="none"
          />
        }
      />
      <VerticalTextAndVisual
        subtitle="Integrations"
        body="Connecting systems for improved efficiency and seamless collaboration through smooth data flow."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="UI/UX design"
            src="../../../images/service-icons-03.png"
            placeholder="none"
          />
        }
      />
      <VerticalTextAndVisual
        subtitle="IT architecture"
        body="Strategizing technology frameworks for seamless digital operations aligned with business goals."
        icon={
          <StaticImage
            style={{ maxWidth: "11rem" }}
            alt="IT architectural planning"
            src="../../../images/service-icons-04.png"
            placeholder="none"
          />
        }
      />
    </Grid>
  );
};

export default Category;
