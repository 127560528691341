import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { Subtitle } from "../../texts/Subtitle";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "../../inputs/Button";
import { Link } from "gatsby";
import { Hero } from "../../texts/Hero";

interface CommunicationProps { }

const SystemCommunication: React.FC<CommunicationProps> = () => {
  return (
    <TextAndVisual
      isVisualMedium
      isVisualLeft
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero>Communication is key</Hero>
          <BodyText textAlign={"left"}>
            We pride ourselves in being a group of communicative IT nerds. An
            oxymoron, you say? Maybe, but we are easy to talk to and get a hold
            of – in fact, all of our team is. Important pieces of information
            should not get lost in translation between the end user and the
            programmer, which is why we make sure they talk to each other often
            in our projects. When working with us, you get access to our entire
            hivemind, and if you want to, we can physically work from your
            premises, so that you can pick our brains in real time.
          </BodyText>
          <BodyText textAlign={"left"}>
            Being communicative also means being open and transparent. Thanks to our
            long experience in the field, we quickly grasp the scope of software
            projects. Our team is flexible and agile. We are skilled in
            visualising solutions, which we deploy continuously – all so that
            you can draw the benefits from our work sooner.
          </BodyText>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end">
          <StaticImage
            alt="We create well-oiled IT solutions"
            src="../../../images/peter-and-christoffer-and-hand.png"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default SystemCommunication;
