import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { Subtitle } from "../../texts/Subtitle";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Button } from "../../inputs/Button";
import { Link } from "gatsby";
import { secondary } from "../../../constants/colors";
import { Hero } from "../../texts/Hero";

interface VisualLabLinkProps { }

const VisualLabLink: React.FC<VisualLabLinkProps> = () => {
  return (
    <TextAndVisual
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero fontSize="2.5rem">Visual lab, engaging digital solutions</Hero>
          <BodyText>
            Our company also provides a holistic range of services including
            content creation, prototyping, UI/UX design and design system
            knowledge, delivering seamless and user-centric digital experiences.
          </BodyText>
          <Link to="/services/visuallab">
            <Button alignSelf="flex-start" colorScheme="secondary">
              Read more
            </Button>
          </Link>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end"
          padding={{ base: "1rem 0", lg: "2rem 1rem" }}
          backgroundColor={secondary}>
          <StaticImage
            alt="We create well-oiled IT solutions"
            src="../../../images/collage-visuallab.png"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default VisualLabLink;
