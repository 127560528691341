import React from "react";
import { TextAndVisual } from "../../layout/TextAndVisual";
import { BodyText } from "../../texts/BodyText";
import { Flex, Stack } from "@chakra-ui/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "../../texts/Hero";

interface GuideProps { }

const Guide: React.FC<GuideProps> = () => {
  return (
    <TextAndVisual
      isVisualMedium
      paddingTop="4rem"
      textPart={
        <Stack spacing={6}>
          <Hero>Guide users trough complex products</Hero>
          <BodyText textAlign={"left"}>
            Planning a digital workflow is an art; it involves guiding the user
            through the experience smoothly, avoiding any frustration caused by
            unclear instructions or hidden features. With a well-designed user
            interface, both the learning curve and user errors can be minimised.
          </BodyText>
          <BodyText textAlign={"left"}>
            At Indevit we can assist you by creating user flows that result in
            satisfied users. We do not only have broad knowledge in code, we
            also know the value of a clear user interface and a guiding user
            experience. We understand that system complexity raises the demands
            for user-friendliness.
          </BodyText>
        </Stack>
      }
      visualPart={
        <Flex justifyContent="flex-end">
          <StaticImage
            alt="We create well-oiled IT solutions"
            src="../../../images/indevit-05.jpg"
            placeholder="none"
          />
        </Flex>
      }
    />
  );
};

export default Guide;
